import Constants from 'expo-constants';

const client = Constants.expoConfig?.extra?.client;
let e;

switch (client) {
    //Switch client - DO NOT EDIT THIS LINE 
    case 'aleph': 
        e = require('./aleph/index');
        break; 
    case 'piel': 
        e = require('./piel/index');
        break; 
    case 'suragaming': 
        e = require('./suragaming/index');
        break; 
    case 'guardiansoftheball': 
        e = require('./guardiansoftheball/index');
        break; 
    case 'sthorm':
        e = require('./sthorm/index');
        break;
    case 'coincaex':
        e = require('./coincaex/index');
        break;
    case 'mellow':
        e = require('./mellow/index');
        break;
    case 'miamisharks':
        e = require('./miamisharks/index');
        break;
    case 'circolo':
        e = require('./circolo/index');
        break;
    case 'lataminnovationsummit':
        e = require('./lataminnovationsummit/index');
        break;
    case 'buroka':
        e = require('./buroka/index');
        break;
    case 'carnaval':
        e = require('./carnaval/index');
        break;
    case 'espaciobitcoin':
        e = require('./espaciobitcoin/index');
        break;
    case 'kriptonmarket':
        e = require('./kriptonmarket/index');
        break;
    case 'ascav':
        e = require('./ascav/index');
        break;
    case 'ctiwallet':
        e = require('./ctiwallet/index');
        break;
    case 'lockertoken':
        e = require('./lockertoken/index');
        break;
    case 'coope':
        e = require('./coope/index');
        break;
    case 'beexo':
        e = require('./beexo/index');
        break;
    case 'latamxo':
        e = require('./latamxo/index');
        break;
    case 'base':
        e = require('./base/index');
        break;
}

export default e;