import { TextStyle } from "react-native";
import RegularText from "./RegularText";
import { settings } from "@styles/globalStyles";

export class BoldText extends RegularText {
    addStyles: () => TextStyle = () => ({ fontFamily: "NunitoBold" });

    calcFontSizeRatio() {
        let fontSizeRatio = settings.fontSizeRatio?.bold || 1;
        return (this.props.fontSize ? this.props.fontSize : 16) * fontSizeRatio;
    }
}

export default BoldText;
