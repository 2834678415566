import React from "react";
import { Platform, StyleSheet,  View, ViewStyle } from "react-native";
import Icon from "@components/icons";
import RegularText from "@base/RegularText";
import { colors, settings } from "@styles/globalStyles";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from "expo-web-browser";
import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { getEnv } from "@utils/helpers/global/global";
import store from "@store/index";
import { hideModal, showModal } from "@store/actions/global";
import i18n from "@i18n/i18n";
import PressableBase from "@base/PressableBase";

WebBrowser.maybeCompleteAuthSession();
const { t } = i18n;

const getClientOAuth = (clientType: string): string | null => {
    const env = getEnv("ENV").toLowerCase() != "production" ? "development" : "production";
    try {
        return getEnv("oAuth")[env][clientType];
    } catch (e) {
        return "";
    }
};

let config: any = {
    webClientId: getClientOAuth("web"),
    androidClientId: getClientOAuth("android"),
    iosClientId: getClientOAuth("ios"),
    expoClientId: getClientOAuth("expo"),
    scopes: ["https://www.googleapis.com/auth/drive.file"],
};

if (Platform.OS == "web") {
    config = {
        ...config,
        responseType: "id_token token",
        expiresIn: 43200,
        extraParams: {
            ...(config.extraParams || {}),
            nonce: Math.random(),
        },
        usePKCE: false,
    };
}

const GoogleButton = (props) => {
    const [request, response, promptAsync] = Google.useAuthRequest(config);

    React.useEffect(() => {
        handleOAuthResponse();
    }, [response]);

    async function handleOAuthResponse() {
        if (response?.type === "success") {
            if (response?.authentication.scope.includes("drive.file")) {
                const oAuthService = await OAuthService.init("google");
                const auth = await oAuthService.auth(response.authentication);
                if (auth) {
                    const { navigation } = props;
                    navigation.navigate(LoginNavigatorScreens.UserOAuth.routeName);
                }
            } else {
                const { navigation } = props;
                navigation.navigate(LoginNavigatorScreens.Welcome.routeName);
                store.dispatch(
                    showModal({
                        avatarIcon: "robot",
                        description: t("google_permission"),
                        onPressClose: () => {
                            store.dispatch(hideModal());
                        },
                    }),
                );
            }
        }
    }

    async function onPress() {
        promptAsync();
    }

    return (
        <PressableBase onPress={onPress}>
            <Row style={[styles.button, { backgroundColor: colors.secondary }]}>
                <Icon style={{ paddingLeft: 5 }} size={20} color={colors.white} name="google"></Icon>
                <View style={{ flex: 1 }}>
                    <RegularText fontSize={17} align="center" color={colors.white}>
                        {t("continue_with")}{" "}
                        <BoldText fontSize={17} color={colors.white}>
                            Google
                        </BoldText>
                    </RegularText>
                </View>
            </Row>
        </PressableBase>
    );
};

const styles = StyleSheet.create({
    button: {
        minWidth: 275,
        padding: 12,
        alignItems: "center",
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderRadius: settings.mainButtonsRadius || 25,
        overflow: "hidden",
        marginVertical: 5,
    },
});

export default GoogleButton;
