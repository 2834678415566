import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready } from "@store/actions/global";
import Constants from "expo-constants";
import { setAllDapps, setWalletConnectDapps, setXoDapps } from "@store/actions/dapps.actions";
import { ModuleControlService, Modules } from "./ModuleControlService";
import { DappModel, WalletConnectDappModel } from "@custom-types/DappModel";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";

export interface IDaapData {
    _id?: string | number;
    description: string;
    url: string;
    logo: string;
    name: string;
}

export default class DappsService {
    private static instance: DappsService;

    constructor() {}

    static getInstance(): DappsService {
        if (!DappsService.instance) {
            DappsService.instance = new DappsService();
        }
        return DappsService.instance;
    }

    async getAllDapps(limited?: boolean) {
        if (!ModuleControlService.getInstance().isModuleEnabled(Modules.dappsModule)) return;

        try {
            let allDapps = store.getState().dapps.allDapps;
            const xoDapps = store.getState().dapps.XoDapps;

            if (limited) {
                const dapps = await this.getXoDapps(limited);
                if (!dapps) return;
                return store.dispatch(setAllDapps(dapps));
            }

            if (xoDapps?.paginator?.page < xoDapps?.paginator?.totalPages) {
                const dapps = await this.getXoDapps();
                if (!dapps) return;
                allDapps = allDapps.concat(dapps);
                return store.dispatch(setAllDapps(allDapps));
            }

            const dapps = await this.getWalletConnectDapps(true);
            if (!dapps) return;
            allDapps = allDapps.concat(dapps);
            return store.dispatch(setAllDapps(allDapps));
        } catch (error) {
            return error;
        }
    }

    async getXoDapps(limited?: boolean) {
        if (!ModuleControlService.getInstance().isModuleEnabled(Modules.dappsModule)) return;
        const xoDapps = store.getState().dapps.XoDapps;
        let limit = limited ? 4 : 10;
        let offset = xoDapps?.docs?.length || 0;

        try {
            const resp = await axios.get(`${getEnv("API_URL")}xodapps?__limit=${limit}&__offset=${offset}`, {
                headers: await ApiService.getAuthHeaders(),
            });

            const docs = resp.data?.docs.map((d: DappModel) => {
                (d.type = "xo"), (d.digitalCurrencies = this.getDigitalCurrenciesToXoDapps(d.digitalCurrencies));
                return d;
            });

            store.dispatch(
                setXoDapps(docs, {
                    page: resp.data?.page,
                    totalDocs: resp.data?.totalDocs,
                    totalPages: resp?.data?.totalPages,
                })
            );
            return resp.data?.docs;
        } catch (error) {}
    }

    async getWalletConnectDapps(addMore?: boolean) {
        if (!ModuleControlService.getInstance().isModuleEnabled(Modules.dappsModule)) return [];

        try {
            let page = 1;
            let entries = 20;
            if (addMore) {
                const walletConnectDapps = store.getState().dapps.WalletConnectDapps;

                page = walletConnectDapps?.paginator?.page + 1;
                if (walletConnectDapps.paginator?.totalDocs / entries <= page && page !== 1) return [];
            }
            const wcProjectId = ModuleControlService.getInstance().getWalletConnectProjectId();
            const url = `https://explorer-api.walletconnect.com/v3/dapps?entries=${entries}&page=${page}&projectId=${wcProjectId}`;
            const resp = await axios.get(url);
            let docs = [];

            const xoDapps = store.getState().dapps.XoDapps?.docs;

            for (const key in resp.data?.listings) {
                const doc = this.equateWalletConnectData(resp.data?.listings[key]);
                if (!xoDapps.some((xoDoc) => xoDoc.name?.toLowerCase() === doc.name?.toLowerCase())) {
                    docs.push(doc);
                }
            }

            let paginator = {
                page: page,
                totalDocs: resp?.data?.total,
            };

            store.dispatch(setWalletConnectDapps(docs, paginator));
            return docs;
        } catch (error) {}
    }

    equateWalletConnectData(data: WalletConnectDappModel) {
        const dapp: DappModel = {
            _id: data.id,
            name: data.name,
            slug: data.slug,
            image: {
                square: data?.image_url?.lg,
                thumbnail: data?.image_url?.md,
            },
            url: data?.app?.browser,
            description: data?.description,
            walletConnectSupport: true,
            xoConnectSupport: false,
            type: "walletConnect",
            digitalCurrencies: this.getDigitalCurrenciesToWalletConnectDapps(data?.chains),
        };
        return dapp;
    }

    getDigitalCurrenciesToWalletConnectDapps(chains): [] {
        let currencies = [];
        try {
            chains.map((chain: any) => {
                const currency: Currency = Wallet.getInstance().findCurrencyByChainId(parseInt(chain.split(":")[1]));
                if (currency) {
                    currencies.push(currency);
                }
            });

            const uniqueCurrencies = [...new Set(currencies)];

            //@ts-ignore
            return uniqueCurrencies;
        } catch {
            //@ts-ignore
            return currencies;
        }
    }

    getDigitalCurrenciesToXoDapps(digitalCurrencies): [] {
        let currencies = [];

        digitalCurrencies.map((c: any) => {
            const currency: Currency = Wallet.getInstance().findCurrencyById(c);
            if (currency) {
                currencies.push(currency);
            }
        });

        //@ts-ignore
        return currencies;
    }

    async getSearchDapps(page: number, filter: string) {
        // try {
        //     const resp = await axios.get(`${getEnv("API_URL")}dapps?page=${page}&name=${filter}`, {
        //         headers: await ApiService.getAuthHeaders(),
        //     });
        //     return resp.data;
        // } catch (error) {
        //     return error;
        // }
    }

    async registerWalletConnectAccess(data, currencies) {
        try {
            const body = {
                name: data?.params?.proposer?.metadata?.name,
                image: {
                    square: data?.params?.proposer?.metadata?.icons[0],
                    thumbnail: data?.params?.proposer?.metadata?.icons[0],
                },
                url: data?.params?.proposer?.metadata?.url,
                description: data?.params?.proposer?.metadata?.description,
                walletConnectSupport: true,
                digitalCurrencies:
                    currencies?.map((c) => {
                        return c.getId();
                    }) || [],
            };
            const resp = await axios.put(`${getEnv("API_URL")}walletconnect/access`, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            return resp.data;
        } catch (error) {
            return error;
        }
    }

    async favoriteDapp(dapp: DappModel) {
        // try {
        //     const resp = await axios.post(
        //         `${getEnv("API_URL")}xodapps/${dapp?._id}/favorite`,
        //         {},
        //         {
        //             headers: await ApiService.getAuthHeaders(),
        //         }
        //     );
        //
        //     return resp.data;
        // } catch (error) {
        //     return error;
        // }
    }
}
