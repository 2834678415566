import React, { Component } from "react";
import i18n from "@i18n/i18n";
import { StyleSheet, View } from "react-native";
import FullLogo from "../assets/images/FullLogo";

interface Props { }

interface State { }

const { t } = i18n;

export default class CustomWelcomeContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <FullLogo
                    width={"100%"}
                    height={200}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});
