import { TextStyle } from 'react-native';
import RegularText from "./RegularText";
import { settings } from '@styles/globalStyles';


export class SemiBoldText extends RegularText {
    addStyles: (() => TextStyle) = () => ({ fontFamily: 'NunitoSemiBold' });
    calcFontSizeRatio() {
        let fontSizeRatio = settings.fontSizeRatio?.semiBold || 1;
        return (this.props.fontSize ? this.props.fontSize : 16) * fontSizeRatio;
    }
}

export default SemiBoldText;