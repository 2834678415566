const nunitoBold = require("./Avenir-Heavy.ttf")
const nunitoLight = require("./Avenir-Light.ttf")
const nunitoRegular = require("./Avenir-Roman.ttf")
const nunitoSemiBold = require("./Avenir-Medium.ttf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
