import Constants from "expo-constants";
import { color } from "react-native-elements/dist/helpers";
import Client from "../../clients/Client";

interface IColors {
    primary: string;
    secondary: string;
    complementary: string;
    tertiary: string;
    background: string;
    gradientFrom: string;
    gradientTo: string;
    buttonGradientFrom: string;
    buttonGradientTo: string;
    text: string;
    shadow: string;
    secondaryShadow: string;
    tertiaryShadow: string;
    grey?: string;
    white?: string;
    black?: string;
    blue?: string;
    green?: string;
    yellow?: string;
    red?: string;
    highlightedText?: string;
    networkSymbol?: string;
    labelBackground?: string;
    labelText?: string;
    latamxo1?: string;
    latamxo2?: string;
    mainButtonsBackground?: string;
    mainButtonsColor?: string;
    balanceColor?: string;
    modalBackground?: string;
    modalBackgroundFrom?: string;
    incognitoButton?: string;
    modalBackgroundTo?: string;
    activeFooter?: string;
    floatButtonText?: string;
    floatButtonBackground?: string;
    inlineButtonText?: string;
    inlineButtonBackground?: string;
    popupBackground?: string;
    avatarBase?: string;
}

var theme = Constants.expoConfig?.extra?.theme || "dark";
var colors: IColors = Client[theme == "dark" ? "darkColors" : "lightColors"];
var clientSettings = Client.settings || {};

//Optionals
colors = {
    ...colors,
    grey: colors.grey || "#9091a4",
    white: colors.white || "white",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    buttonGradientFrom: colors.buttonGradientFrom || colors.secondary,
    buttonGradientTo: colors.buttonGradientTo || colors.secondary,
    highlightedText: colors.highlightedText || colors.text,
    networkSymbol: colors.networkSymbol || colors.text,
    labelBackground: colors.labelBackground || colors.secondary,
    labelText: colors.labelText || colors.text,
    latamxo1: colors.latamxo1 || "#fff",
    latamxo2: colors.latamxo2 || "#fcfcfc",
    mainButtonsBackground: colors.mainButtonsBackground || colors.secondary,
    mainButtonsColor: colors.mainButtonsColor || colors.white,
    balanceColor: colors.balanceColor || color.text,
    popupBackground: colors.popupBackground || colors.primary,
    modalBackground: colors.modalBackground || colors.primary,
    modalBackgroundFrom: colors.modalBackgroundFrom || colors.tertiary,
    modalBackgroundTo: colors.modalBackgroundTo || colors.secondary,
    activeFooter: colors.activeFooter || colors.text,
    floatButtonText: colors.floatButtonText || colors.text,
    floatButtonBackground: colors.floatButtonBackground || colors.complementary || colors.secondary,
    inlineButtonText: colors.inlineButtonText || colors.white,
    inlineButtonBackground: colors.inlineButtonBackground || colors.secondary,
    avatarBase: colors.avatarBase || colors.white || colors.complementary,
    incognitoButton: colors.incognitoButton || "black",
};

const settings = {
    maxWidth: clientSettings.maxWidth || 600,
    maxMobileWidth: clientSettings.maxMobileWidth || 600,
    cardRadius: clientSettings.cardRadius !== undefined ? clientSettings.cardRadius : 10,
    fontSizeRatio: {
        light: clientSettings.fontSizeRatio?.light !== undefined ? clientSettings.fontSizeRatio?.light : 1,
        regular: clientSettings.fontSizeRatio?.regular !== undefined ? clientSettings.fontSizeRatio?.regular : 1,
        semiBold: clientSettings.fontSizeRatio?.semiBold !== undefined ? clientSettings.fontSizeRatio?.semiBold : 1,
        bold: clientSettings.fontSizeRatio?.bold !== undefined ? clientSettings.fontSizeRatio?.bold : 1,
    },
    mainButtonsRadius: clientSettings.mainButtonsRadius !== undefined ? clientSettings.mainButtonsRadius : 30,
    shadow: {
        shadowColor: clientSettings.shadow?.shadowColor || colors.black,
        shadowOpacity: clientSettings.shadow?.shadowOpacity || 0,
        shadowRadius: clientSettings.shadow?.shadowRadius || 0,
    },
    gradientLocations: clientSettings.gradientLocations || [0, 1],
    gradientStart: clientSettings.gradientStart || {},
    gradientEnd: clientSettings.gradientEnd || {},
};

export { IColors, colors, settings };
